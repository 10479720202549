import { Route, Routes } from 'react-router-dom';
import KosaAccounting from './KosaAccounting';
import History from './History';

const KosaComponentsRouter = () => {
    return (
        <Routes>
            <Route path="/history/user/:user" element={<History />} />
            <Route path="/history/component/:component" element={<History />} />
            <Route path="/history/kosa/:kosa" element={<History />} />
            <Route path="/history" element={<History />} />
            <Route path="/" element={<KosaAccounting />} />
        </Routes>
    );
};

export default KosaComponentsRouter;

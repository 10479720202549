import api from '../http';

export default class TklService {
    static async getTypes() {
        return api.get('/tkl/types');
    }

    //-------------------------------------------------------------------------------------------------
    static async getStatuses() {
        return api.get('/tkl/states');
    }
    //-------------------------------------------------------------------------------------------------
    static async size(filter = {}) {
        return api.get('/tkl/size', { params: filter });
    }

    //-------------------------------------------------------------------------------------------------
    static async get(start, size, filter = {}, sort = {}) {
        return api.get('/tkl', {
            params: {
                offset: start,
                limit: size,
                ...filter,
                ...sort,
            },
        });
    }

    //-------------------------------------------------------------------------------------------------
    static async add(tkl) {
        return api.put('/tkl', tkl);
    }

    //-------------------------------------------------------------------------------------------------
    static async update(tkl) {
        return api.post('/tkl', tkl);
    }

    //-------------------------------------------------------------------------------------------------
    static async remove(tklId) {
        return api.delete(`/tkl/${tklId}`);
    }

    //-------------------------------------------------------------------------------------------------
    static async history(tklId) {
        return api.get(`/tkl/history/${tklId}`);
    }

    //-------------------------------------------------------------------------------------------------
    static async notes(tklId) {
        return api.get(`/tkl/notes/${tklId}`);
    }
}

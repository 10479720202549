import api from '../http';

export default class BuyersService {
    static async size(filter = {}) {
        return api.get('/buyers/size', { params: filter });
    }

    static async list(filter = {}, params = {}) {
        return api.get('/buyers', { params: { ...filter, ...params } });
    }

    static async idNameList() {
        return api.get('/buyers/names-list');
    }

    /**
     * Проверяет есть ли заказчик с указанным именем в базе данных
     * @param {String} name - имя заказчика для проверки
     * @returns {Boolean} true - если имя уже занято
     */
    static async checkName(name) {
        return api.post('buyers/check-name', { name });
    }

    /**
     * Создать нового заказчика
     * @param {String} name - имя заказчика
     * @returns {name, buyer_id} - данные созданного заказчика
     */
    static async create(name, inn = null) {
        return api.post('buyers/create', { name: name, inn: inn });
    }

    /**
     * Получить данные о заказчике
     * @param {Number} buyerId - id заказчика данные о котором хотим получить
     * @returns {buyer_id, name, inn, additional} - информация о заказчике
     */
    static async getBuyer(buyerId) {
        return api.get(`/buyers/${buyerId}`);
    }

    /**
     * обновить информацию о заказчике в бд
     * @param {buyer_id, name, inn, additional} buyer - информация о заказчике
     * @returns true если все прошло удачно
     */
    static async update(buyer) {
        return api.post('/buyers/update', { buyer });
    }
}

import api from '../http';

export default class AccountingService {
    static async accounting(filter = {}, sort = {}) {
        return api.get('/accounting', { params: { ...filter, ...sort } });
    }

    static async historySize(filter = {}) {
        return api.get('/accounting/history/size', { params: { ...filter } });
    }
    static async history(filter = {}, limit = 20, offset = 0) {
        return api.get('/accounting/history', { params: { ...filter, limit: limit, offset: offset } });
    }

    static async correction(id, quantity, note = null) {
        console.log('correction >>>', id, quantity, note);

        return api.post('/accounting', { id: id, quantity: quantity, note: note });
    }
}

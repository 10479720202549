import { createContext, useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import AdditionInformationStore from '../../store/addition-information-store';
import Header from '../Header';
import OrderRoutes from '../Orders';
import UserManager from '../UserManager';
import BuyerRoutes from '../Buyers';
import Production from '../Production';
import Purchase from '../Purchase';
import { AuthContext } from '../..';
import useMedia from '../../services/media-query';
import FormulaRouters from '../Formula';
import KosaList from '../KosaList';
import KosaHistory from '../KosaHistory';
import DeviceAccounting from '../DeviceAccounting';
import KosaComponentsRouter from '../KosaAccounting';

const additionInformationStore = new AdditionInformationStore();

export const AdditionInfoContext = createContext({ additionInformationStore });

const HomePage = () => {
    const { isMobile } = useMedia();
    const { authStore } = useContext(AuthContext);

    const st = isMobile ? null : { margin: '1em' };
    if (authStore.user.role === 2) {
        return (
            <>
                <Header />
                <div style={st}>
                    <OrderRoutes />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Header />
                <div style={st}>
                    <Production />
                </div>
            </>
        );
    }
};

const Router = () => {
    const location = useLocation();
    const state = location.state;

    return (
        <>
            <Routes location={state?.backgroundLocation || location}>
                <Route path="" element={<HomePage />} />
                <Route path="/" element={<Header />}>
                    <Route path="users" element={<UserManager />} />
                    <Route path="production/*" element={<Production />} />
                    <Route path="orders/*" element={<OrderRoutes />} />
                    <Route path="buyers/*" element={<BuyerRoutes />} />
                    <Route path="formula/*" element={<FormulaRouters />} />
                    <Route path="kosas" element={<KosaList />} />
                    <Route path="kosas/:sn" element={<KosaHistory />} />
                    <Route path="kosa-components/*" element={<KosaComponentsRouter />} />
                    <Route path="tkl" element={<DeviceAccounting />} />
                    <Route path="grocery-list" element={<Purchase />} />
                </Route>
            </Routes>
            {state?.backgroundLocation && (
                <Routes>
                    <Route path="/formula/*" element={<FormulaRouters />} />
                    <Route path="kosas/:sn" element={<KosaHistory />} />
                </Routes>
            )}
        </>
    );
};

const ContentRoot = () => {
    const { authStore } = useContext(AuthContext);

    useEffect(() => {
        //выполнять обновление токена каждые 28 минут
        const intervalId = setInterval(authStore.refresh, 28 * 60_000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (authStore.user) {
            loadInfo();
        }
    }, [authStore.user]);

    const loadInfo = async () => {
        try {
            await additionInformationStore.load();
        } catch (e) {
            console.warn(e);
        }
    };

    return (
        <AdditionInfoContext.Provider value={{ additionInformationStore }}>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </AdditionInfoContext.Provider>
    );
};

export default ContentRoot;

import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import {
    Alert,
    Link,
    Paper,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    TextField,
    Box,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { AdditionInfoContext } from '../ContentRoot/ContentRoot';
import BuyersService from '../../services/buyersService';
import Loading from '../Loading';
import Additional from './Additional';
import { OrderStore } from '../../store/orderStore';
import { StyledTableCell, BackButton } from '../StyledComponents';

const orderStore = new OrderStore();

const dateToStr = (data) => {
    const oData = DateTime.fromISO(data);
    return oData.toLocaleString(DateTime.DATETIME_SHORT);
};

const Buyer = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { additionInformationStore } = useContext(AdditionInfoContext);

    const [buyer, setBuyer] = useState(null);
    const [loadError, setLoadError] = useState(''); // не использовать для других ошибок

    const params = useParams();

    const getInfo = async () => {
        try {
            const buyer_id = params.id;
            await orderStore.setFilter({ buyer_id: buyer_id });
            const response = await BuyersService.getBuyer(buyer_id);
            setBuyer(response.data);
        } catch (e) {
            console.log(e);
            setLoadError('Ошибка получения данных с сервера');
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    //Если нет данных, то и отображать нечего
    if (!buyer && loadError.length === 0) {
        return <Loading />; //Идет запрос... показываем затычку... В будущем можно переделать на скелет
    } else if (loadError.length > 0) {
        //Запрос закончился ошибкой
        return (
            <Snackbar open={true} autoHideDuration={30000}>
                <Alert severity="error">{loadError}</Alert>
            </Snackbar>
        );
    }

    const updateBuyer = async (newBuyer, str = null) => {
        try {
            const response = await BuyersService.update(newBuyer);
            if (response.data !== true) {
                enqueueSnackbar('Что-то пошло не так', { variant: 'error' });
                return;
            }
        } catch (e) {
            enqueueSnackbar('Ошибка сохранения заказчика', { variant: 'error' });
        }
        getInfo();
    };

    const onNameChange = (event) => {
        const newBuyer = { ...buyer, name: event.target.value };
        setBuyer(newBuyer);
    };
    const onEndNameEdit = (event) => {
        const newBuyer = { ...buyer, name: event.target.value };
        updateBuyer(newBuyer, 'имя');
    };
    const onInnChange = (event) => {
        const newBuyer = { ...buyer, inn: event.target.value };
        setBuyer(newBuyer);
    };
    const onEndInnEdit = (event) => {
        const newBuyer = { ...buyer, inn: event.target.value };
        updateBuyer(newBuyer, 'инн');
    };
    const onAdditionalChange = (nVal) => {
        const newBuyer = { ...buyer, additional: nVal };
        updateBuyer(newBuyer, 'дополнительной информации');
    };

    const renderTableRows = () => {
        return orderStore.orders.map((order) => {
            return (
                <TableRow>
                    <TableCell>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Link href={`/orders/${order.order_id}`}>{order.number}</Link>
                            {order.has_files && <AttachFileIcon fontSize="small" color="success" />}
                        </Box>
                    </TableCell>
                    <TableCell>{dateToStr(order.create_time)}</TableCell>
                    <TableCell>{additionInformationStore.statusStr(order.state_id)}</TableCell>
                </TableRow>
            );
        });
    };

    return (
        <>
            <Stack spacing={2}>
                <Stack direction="row">
                    <BackButton />
                    <Typography variant="h5" sx={{ marginTop: '0.2em' }}>
                        Заказчик
                    </Typography>
                </Stack>

                <Stack padding="1em" spacing={2}>
                    <TextField
                        label="Название"
                        size="small"
                        value={buyer.name}
                        onChange={onNameChange}
                        onBlur={onEndNameEdit}
                    />
                    <TextField
                        label="ИНН"
                        size="small"
                        value={buyer.inn}
                        onChange={onInnChange}
                        onBlur={onEndInnEdit}
                    />
                </Stack>
            </Stack>
            <div style={{ margin: '0 1em' }}>
                <Additional additional={buyer.additional ? buyer.additional : []} onChange={onAdditionalChange} />
            </div>
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Заказ №</StyledTableCell>
                            <StyledTableCell>Создан</StyledTableCell>
                            <StyledTableCell>Статус</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderTableRows()}</TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    component="div"
                                    count={orderStore.allOrdersSize}
                                    rowsPerPage={orderStore.pageSize}
                                    page={orderStore.page}
                                    onPageChange={(_, p) => orderStore.setPage(p)}
                                    onRowsPerPageChange={(e) => orderStore.setPageSize(e.target.value)}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

export default Buyer;

const localizeTime = (time) => {
    const date = new Date(time);
    const formatter = new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
    return formatter.format(date);
};

export default localizeTime;

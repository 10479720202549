import { useEffect } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';

import AccountingService from '../../services/accounting-service';
import useErrorHandler from '../../services/error-handler';
import { StyledTableCell } from '../StyledComponents';
import { useParams } from 'react-router-dom';

class Store {
    loading = false;

    size = 0;
    pageSize = 20;
    page = 0;

    items = [];

    filter = {};

    constructor() {
        makeAutoObservable(this);
    }

    async loadSize() {
        this.loading = true;
        try {
            const response = await AccountingService.historySize(this.filter);
            this.size = +response.data;
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    async loadItems() {
        this.loading = true;
        try {
            const start = this.page * this.pageSize;
            const response = await AccountingService.history(this.filter, this.pageSize, start);
            this.items = response.data;
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    async load() {
        await this.loadSize();
        await this.loadItems();
    }

    async setFilters(filter = {}) {
        this.filter = filter;
        await this.load();
    }

    async setPage(page) {
        this.page = page;
        await this.loadItems();
    }

    async setPageSize(pageSize) {
        this.pageSize = +pageSize;
        await this.loadItems();
    }
}

const store = new Store();

//=================================================================================================
const History = () => {
    const { user, component, kosa } = useParams();

    const errorHandler = useErrorHandler();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            if (user) await store.setFilters({ us: +user });
            else if (component) await store.setFilters({ comp: +component });
            else if (kosa) await store.setFilters({ kosa: +kosa });
            else await store.load();
        } catch (e) {
            errorHandler(e);
        }
    };

    const onPageChange = async (_, page) => {
        try {
            await store.setPage(page);
        } catch (e) {
            errorHandler(e);
        }
    };

    const onRowsPerPageChange = async (event) => {
        try {
            await store.setPageSize(event.target.value);
        } catch (e) {
            errorHandler(e);
        }
    };

    const createOnFilterChangeFor = (filterName) => {
        return async (event) => {
            try {
                const filter = { ...store.filter, us: null, comp: null, kosa: null, [filterName]: event.target.value };
                await store.setFilters(filter);
            } catch (e) {
                errorHandler(e);
            }
        };
    };

    const renderTableBody = () => {
        return store.items.map((item, index) => (
            <TableRow hover key={index}>
                <TableCell>{DateTime.fromISO(item.time).toFormat('dd.MM.yyyy HH:mm')}</TableCell>
                <TableCell>{item.uname}</TableCell>
                <TableCell>{item.component}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.kosa ? item.kosa : ''}</TableCell>
                <TableCell>{item.note ? item.note : ''}</TableCell>
            </TableRow>
        ));
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                История изменений компонентов косс
            </Typography>
            <Stack
                direction="row"
                spacing={2}
                useFlexGap
                justifyContent="center"
                alignItems="center"
                sx={{ flexWrap: 'wrap' }}
            >
                <Box sx={{ m: 'auto' }} />
                <TextField
                    size="small"
                    label="Пользователь"
                    sx={{ width: '10em' }}
                    onChange={createOnFilterChangeFor('uname')}
                />
                <TextField
                    size="small"
                    label="Компонент"
                    sx={{ width: '10em' }}
                    onChange={createOnFilterChangeFor('cname')}
                />
                <TextField
                    size="small"
                    label="Коса"
                    sx={{ width: '10em' }}
                    onChange={createOnFilterChangeFor('kosa')}
                />
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Время</StyledTableCell>
                            <StyledTableCell>Пользователь</StyledTableCell>
                            <StyledTableCell>Компонент</StyledTableCell>
                            <StyledTableCell>Действие</StyledTableCell>
                            <StyledTableCell>Коса</StyledTableCell>
                            <StyledTableCell>Комментарий</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderTableBody()}</TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <TablePagination
                                    rowsPerPageOptions={[20, 50, 100]}
                                    component="div"
                                    count={store.size}
                                    rowsPerPage={store.pageSize}
                                    page={store.page}
                                    onPageChange={onPageChange}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Stack>
    );
};

export default observer(History);

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import KosaService from '../../services/kosa-service';
import localizeTime from '../../services/localize-time';
import useErrorHandler from '../../services/error-handler';
import { StyledDialogTitle, StyledTableCell } from '../StyledComponents';
import { stateColor, stateStr } from '../../models/kosaState';

//=================================================================================================
class Store {
    load = false;
    items = [];

    constructor() {
        makeAutoObservable(this);
    }

    async get(sn) {
        if (!sn) {
            this.items = [];
            return;
        }
        this.load = true;
        try {
            const answer = await KosaService.history(sn);
            this.items = answer.data;
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки данных');
        } finally {
            this.load = false;
        }
    }
}

const store = new Store();

//=================================================================================================
const HistoryItem = ({ item }) => {
    return (
        <TableRow sx={{ backgroundColor: stateColor(item.state_id) }}>
            <TableCell>{localizeTime(item.time)}</TableCell>
            <TableCell>{stateStr(item.state_id)}</TableCell>
            <TableCell>{item.first_name + ' ' + item.last_name}</TableCell>
        </TableRow>
    );
};

//=================================================================================================
const History = observer(({ sn, onClose }) => {
    const pushError = useErrorHandler();

    useEffect(() => {
        if (sn) load();
    }, [sn]);

    const load = async () => {
        try {
            await store.get(sn);
        } catch (e) {
            pushError(e);
        }
    };

    const renderTable = () => {
        if (store.load) return <div>Загрузка...</div>;
        if (store.items.length === 0) return <div>Нет данных</div>;
        return (
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Время</StyledTableCell>
                            <StyledTableCell>Статус</StyledTableCell>
                            <StyledTableCell>Пользователь</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {store.items.map((item) => (
                            <HistoryItem key={item.time} item={item} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Dialog open={Boolean(sn)} onClose={onClose}>
            <StyledDialogTitle>История косы {sn}</StyledDialogTitle>
            <DialogContent>
                <Typography sx={{ textAlign: 'center' }}>
                    История косы <b>{sn}</b> {store.items.length ? ' - ' + store.items.at(0).marking : ''}
                </Typography>
            </DialogContent>
            <DialogContent>{renderTable()}</DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onClose}>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
});

//=================================================================================================
const KosaHistory = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const sn = +params.sn;

    const onClose = () => {
        const address = location.state ? location.state.backgroundLocation.pathname : '/kosas/' + sn;
        navigate(address, { state: { refresh: true }, replace: true });
    };

    return <History sn={sn} onClose={onClose} />;
};

export default KosaHistory;

import { useEffect, useState } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import useErrorHandler from '../../services/error-handler';
import AccountingService from '../../services/accounting-service';
import { StyledDialogTitle, StyledSortLabel, StyledTableCell } from '../StyledComponents';

const createItemList = (items) => {
    const unique = {};
    const result = [];
    for (const item of items) {
        if (unique[item.type_id]) continue;
        unique[item.type_id] = true;
        result.push({
            type_id: item.type_id,
            type: item.type,
        });
    }
    return result;
};

//=================================================================================================
class Store {
    loading = false;
    items = [];
    deviceTypes = [];

    filter = {};
    sort = {};

    constructor() {
        makeAutoObservable(this);
    }

    async load() {
        this.loading = true;
        try {
            const response = await AccountingService.accounting(this.filter, this.sort);
            this.items = response.data;
            if (this.deviceTypes.length === 0) this.deviceTypes = createItemList(response.data); // Только при первом запросе
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    async setFilter(filter) {
        this.filter = filter;
        await this.load();
    }

    async setSort(sort) {
        this.sort = sort;
        await this.load();
    }

    async update(item, quantity, note) {
        await AccountingService.correction(item.component_id, quantity, note);
        await this.load();
    }
}

const store = new Store();

//=================================================================================================
const CorrectionDialog = ({ item, onClose }) => {
    const errorHandler = useErrorHandler();

    const [quantity, setQuantity] = useState('');
    const [note, setNote] = useState('');

    useEffect(() => {
        if (!item) setQuantity('');
    });

    const onQuantityChange = (event) => {
        let input = event.target.value;
        if (!/^[-\d.,]*$/.test(input)) return;

        input = input.replace(',', '.');
        if ((input.match(/-/g) || []).length > 1 || (input.includes('-') && input[0] !== '-')) return;

        if ((input.match(/\./g) || []).length > 1) return;
        setQuantity(input);
    };

    const onApplyButtonClick = async () => {
        try {
            if (isNaN(+quantity)) throw new Error('Указано не возможное количество компонентов');
            const n = note.length > 0 ? note : null;
            await store.update(item, +quantity, n);
            onClose();
        } catch (e) {
            errorHandler(e);
        }
    };

    if (!item) return null;
    return (
        <Dialog open={item} onClose={onClose} disableRestoreFocus>
            <StyledDialogTitle>Коррекция наличия компонентов</StyledDialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography>Корректировать наличие компонента на указанную величину:</Typography>
                </DialogContentText>
                <TextField
                    size="small"
                    fullWidth
                    value={quantity}
                    onChange={onQuantityChange}
                    inputProps={{ inputMode: 'decimal', pattern: '[-]?[0-9]*[.,]?[0-9]*' }}
                />
                <DialogContentText sx={{ mt: 2 }}>
                    <Typography>Причина корректировки:</Typography>
                </DialogContentText>
                <TextField
                    size="small"
                    fullWidth
                    multiline
                    maxRows={5}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button color="success" variant="contained" onClick={onApplyButtonClick} autoFocus>
                    Применить
                </Button>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//=================================================================================================
const Filter = observer(() => {
    const errorHandler = useErrorHandler();

    const onNameFilterChange = async (event) => {
        try {
            const oldFilter = store.filter;
            let value = event.target.value;
            if (!value.length) value = null;
            store.setFilter({ ...oldFilter, name: value });
        } catch (e) {
            errorHandler(e);
        }
    };

    const onTypeFilterChange = async (event) => {
        try {
            const oldFilter = store.filter;
            let value = +event.target.value;
            if (isNaN(value)) value = null;
            store.setFilter({ ...oldFilter, type: value });
        } catch (e) {
            errorHandler(e);
        }
    };

    //-------------------------------------------------------------------------------------------------
    const renderTypeMenu = () => {
        const menu = store.deviceTypes.map((item) => (
            <MenuItem value={item.type_id} key={item.type_id}>
                {item.type}
            </MenuItem>
        ));
        menu.unshift(
            <MenuItem value={null} key={0}>
                Все
            </MenuItem>
        );
        return menu;
    };

    //-------------------------------------------------------------------------------------------------
    return (
        <Stack
            direction="row"
            spacing={2}
            useFlexGap
            justifyContent="center"
            alignItems="center"
            sx={{ flexWrap: 'wrap' }}
        >
            <Box sx={{ m: 'auto' }} />
            <FormControl>
                <InputLabel id="component-type-select" size="small">
                    Тип компонента
                </InputLabel>
                <Select
                    sx={{ minWidth: '10em' }}
                    label="Тип компонента"
                    labelId="component-type-select"
                    variant="outlined"
                    size="small"
                    onChange={onTypeFilterChange}
                >
                    {renderTypeMenu()}
                </Select>
            </FormControl>
            <TextField size="small" label="Наименование" sx={{ width: '10em' }} onChange={onNameFilterChange} />
            <Button color="primary" variant="contained" href="/kosa-components/history">
                История
            </Button>
        </Stack>
    );
});

//=================================================================================================
const LinkButton = styled(Button)({ textTransform: 'none' });

//=================================================================================================
const KosaAccounting = () => {
    const errorHandler = useErrorHandler();

    const [correctionDialog, setCorrectionDialog] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            await store.load();
        } catch (e) {
            errorHandler(e);
        }
    };

    //-------------------------------------------------------------------------------------------------
    const renderTableHeader = () => {
        const sortHandler = async (sortColumnName) => {
            try {
                const isAsc = store.sort.column === sortColumnName && store.sort.order === 'asc';
                const sort = {
                    column: sortColumnName,
                    order: isAsc ? 'desc' : 'asc',
                };
                await store.setSort(sort);
            } catch (e) {
                errorHandler(e);
            }
        };

        // prettier-ignore
        const items = [
            { id: 'type_id',     label: 'Тип',          sort: true  },
            { id: 'name',        label: 'Наименование', sort: true  },
            { id: 'description', label: 'Описание',     sort: true  },
            { id: 'quantity',    label: 'Количество',   sort: true  },
            { id: 'unit',        label: 'Единицы',      sort: true  },
            { id: 'actions',     label: '',             sort: false },
        ];

        const out = [];
        items.forEach((item) => {
            if (item.sort) {
                out.push(
                    <StyledTableCell
                        key={item.id}
                        align="center"
                        sortDirection={store.sort.column === item.id ? store.sort.order : false}
                    >
                        <StyledSortLabel
                            active={store.sort.column === item.id}
                            direction={store.sort.order}
                            onClick={() => sortHandler(item.id)}
                        >
                            {item.label}
                        </StyledSortLabel>
                    </StyledTableCell>
                );
            } else {
                out.push(
                    <StyledTableCell key={item.id} align="center">
                        {item.label}
                    </StyledTableCell>
                );
            }
        });
        return out;
    };

    //-------------------------------------------------------------------------------------------------
    const renderTableBody = () => {
        return store.items.map((item) => (
            <TableRow hover key={item.component_id}>
                <TableCell align="right">{item.type}</TableCell>
                <TableCell>
                    <LinkButton href={`kosa-components/history/component/${item.component_id}`}>{item.name}</LinkButton>
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell align="right">
                    <Tooltip title="Корректировать">
                        <LinkButton onClick={() => setCorrectionDialog(item)}>
                            {item.unit === 'шт.' ? Math.round(item.quantity) : item.quantity}
                        </LinkButton>
                    </Tooltip>
                </TableCell>
                <TableCell>{item.unit}</TableCell>
                <TableCell align="right">
                    <Tooltip title="Корректировать">
                        <IconButton onClick={() => setCorrectionDialog(item)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <>
            <Stack spacing={2}>
                <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Учет компонентов кос
                </Typography>
                <Filter />
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>{renderTableHeader()}</TableRow>
                        </TableHead>
                        <TableBody>{renderTableBody()}</TableBody>
                    </Table>
                </TableContainer>
            </Stack>
            <CorrectionDialog item={correctionDialog} onClose={() => setCorrectionDialog(null)} />
        </>
    );
};

export default observer(KosaAccounting);

import { useEffect, useState } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import useErrorHandler from '../../services/error-handler';
import TklService from '../../services/tkl-service';
import { FocusableTextField, StyledDialogTitle, StyledSortLabel, StyledTableCell } from '../StyledComponents';
import RemoveDialog from '../Orders/RemoveDialog';
import localizeTime from '../../services/localize-time';

//=================================================================================================
/*
const tklTypes = new Map([
    [1, 'TKL'],
    [2, 'TKL-Rf'],
    [3, 'TKL-Modbus'],
    [11, 'TKL-Log'],
    [12, 'TKL-LogRF'],
    [13, 'TKL-LogLW'],
    [14, 'TKL-LogNB'],
    [15, 'TKL-LogGSM'],
    [16, 'TKL-LogSAT'],
    [21, 'TKL-ComMB'],
]);
*/
const additionalInfo = new Map([
    [1, []],
    [2, []],
    [3, []],
    [11, []],
    [12, []],
    [13, ['DevUi', 'AppKey']],
    [14, ['IMEI', 'IMSI', 'ICCID']],
    [15, ['IMEI']],
    [16, ['IMEI']],
    [21, []],
]);
//=================================================================================================
class TklStore {
    loading = false;

    allSize = 0;

    pageSize = 20;
    page = 0;
    items = [];

    filter = {};
    sort = { column: 'id', order: 'desc' };

    types = [];
    statuses = [];

    //-------------------------------------------------------------------------------------------------
    constructor() {
        makeAutoObservable(this);
    }

    //-------------------------------------------------------------------------------------------------
    type(typeId) {
        const type = this.types.find((type) => type.type_id === typeId);
        return type ? type.name : '';
    }

    //-------------------------------------------------------------------------------------------------
    state(stateId) {
        const state = this.statuses.find((state) => state.state_id === stateId);
        return state ? state.state : '';
    }

    //-------------------------------------------------------------------------------------------------
    async loadTypes() {
        const response = await TklService.getTypes();
        this.types = response.data;
    }

    //-------------------------------------------------------------------------------------------------
    async loadStatuses() {
        const response = await TklService.getStatuses();
        this.statuses = response.data;
    }

    //-------------------------------------------------------------------------------------------------
    async loadSize() {
        this.loading = true;
        try {
            const response = await TklService.size(this.filter);
            this.allSize = +response.data;
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    //-------------------------------------------------------------------------------------------------
    async loadItems() {
        this.loading = true;
        try {
            const response = await TklService.get(this.page * this.pageSize, this.pageSize, this.filter, this.sort);
            this.items = response.data;
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    //-------------------------------------------------------------------------------------------------
    async setFilters(filter = {}) {
        this.filter = filter;
        // console.log(filter);
        await this.loadSize();
        await this.loadItems();
    }

    //-------------------------------------------------------------------------------------------------
    async setSort(sorting = {}) {
        this.sort = sorting;
        // console.log(sorting);
        await this.loadItems();
    }

    //-------------------------------------------------------------------------------------------------
    async setPage(page) {
        this.page = page;
        await this.loadItems();
    }

    //-------------------------------------------------------------------------------------------------
    async setPageSize(pageSize) {
        this.pageSize = pageSize;
        await this.loadItems();
    }
    //-------------------------------------------------------------------------------------------------
    async create(tkl) {
        await TklService.add(tkl);
        await this.loadSize();
        await this.loadItems();
    }

    //-------------------------------------------------------------------------------------------------
    async update(tkl) {
        await TklService.update(tkl);
        await this.loadItems();
    }

    //-------------------------------------------------------------------------------------------------
    async remove(tkl) {
        await TklService.remove(tkl.id);
        await this.loadSize();
        await this.loadItems();
    }
}

const tklStore = new TklStore();

//=================================================================================================
const SeveralDialog = ({ open, startSn, count, onClose, onApply }) => {
    return (
        <Dialog open={open} onClose={onClose} disableRestoreFocus>
            <StyledDialogTitle>Добавить {count} устройств</StyledDialogTitle>
            <DialogContentText>
                <Typography align="center">
                    {startSn
                        ? 'Добавить устройства с серийными номерами ' + startSn + ' - ' + (startSn + count - 1) + '?'
                        : 'Добавить ' + count + ' новых устройств?'}
                </Typography>
            </DialogContentText>
            <DialogActions>
                <Button color="success" onClick={onApply} variant="contained" autoFocus>
                    Добавить
                </Button>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//=================================================================================================
const TklDialog = ({ tkl, title, applyButtonText, onClose, onApply, several = false }) => {
    const errorHandler = useErrorHandler();

    const [sn, setSn] = useState(undefined);
    const [type, setType] = useState(1);
    const [hw, setHw] = useState('');
    const [fw, setFw] = useState('');
    const [note, setNote] = useState('');
    const [info, setInfo] = useState([]); //[{ name: 'description', value: '' }, ...]
    const [state, setState] = useState(1);

    const [more, setMore] = useState(false);
    const [severalCount, setSeveralCount] = useState(2);
    const [showSeveralDialog, setShowSeveralDialog] = useState(false);

    useEffect(() => {
        if (tkl) init(tkl);
    }, [tkl]);

    //-------------------------------------------------------------------------------------------------
    const init = (tkl) => {
        const addInfo = Object.entries(tkl.info).map(([n, v]) => ({ name: n, value: v }));
        setSn(+tkl.sn);
        setType(+tkl.type_id);
        setHw(tkl.hw_version);
        setFw(tkl.fw_version);
        setNote(tkl.note);
        setState(+tkl.state_id);
        setInfo(addInfo);
    };

    //-------------------------------------------------------------------------------------------------
    const onSnChanged = (e) => {
        let val = +e.target.value;
        if (!isNaN(val)) setSn(val);
    };

    //-------------------------------------------------------------------------------------------------
    const onSeveralChanged = (e) => {
        const val = +e.target.value;
        if (!isNaN(val)) setSeveralCount(val);
    };

    //-------------------------------------------------------------------------------------------------
    const onStateChanged = (e) => {
        let val = +e.target.value;
        if (!isNaN(val)) setState(val);
    };

    //-------------------------------------------------------------------------------------------------
    const onTypeChanged = (e) => {
        const val = +e.target.value;
        if (val) {
            const nInfo = [];
            const additionalFieldNames = additionalInfo.get(val);
            if (additionalFieldNames && additionalFieldNames.length) {
                additionalFieldNames.forEach((name) => {
                    const item = info.find((item) => item.name === name);

                    if (item) nInfo.push(item);
                    else nInfo.push({ name: name, value: '' });
                });
            }
            // Здесь я имею в nInfo массив обязательных свойств для выбранного типа устройства.
            // Осталось дополнить его свойствами которые уже были установлены ранее...
            let otherInfo = info
                .filter((item) => !additionalFieldNames.includes(item.name)) //все свойства кроме обязательных
                .filter((item) => item.name !== '' && item.value !== ''); //все заполненные свойства
            nInfo.push(...otherInfo);
            setInfo(nInfo);

            setType(val);
        }
    };

    //-------------------------------------------------------------------------------------------------
    const onAddInfoClick = () => {
        const nInfo = [...info];
        nInfo.push({ name: '', value: '' });
        setInfo(nInfo);
    };

    //-------------------------------------------------------------------------------------------------

    /**
     * @function
     * @name makeTkl
     * @description Формирует объект tkl из состояния компонента
     * @param {number} [i=0] - смещение для sn, если нужно сгенерировать несколько устройств
     * @returns {Object} Объект tkl, содержащий sn, type_id, hw_version, fw_version, state_id, note, info
     */
    const makeTkl = (i = 0) => {
        const addInfo = info.reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
        }, {});
        const tkl = {
            sn: sn ? sn + i : null,
            type_id: type,
            hw_version: hw,
            fw_version: fw,
            state_id: state,
            note: note,
            info: addInfo,
        };
        return tkl;
    };

    const createTklOnServer = async (tkl) => {
        try {
            await tklStore.create(tkl);
        } catch (e) {
            errorHandler(e);
        }
    };

    //-------------------------------------------------------------------------------------------------
    const onApplyButtonClick = () => {
        if (more) {
            setShowSeveralDialog(true);
        } else {
            const tkl = makeTkl();
            createTklOnServer(tkl);
            onApply(tkl);
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') onApplyButtonClick();
    };

    //-------------------------------------------------------------------------------------------------
    const onSeveralApplyButtonClick = () => {
        for (let i = 0; i < severalCount; i++) {
            const tkl = makeTkl(i);
            createTklOnServer(tkl);
        }
        onApply(tkl);
    };

    //-------------------------------------------------------------------------------------------------
    const renderTypeMenu = () => {
        return tklStore.types.map((type) => (
            <MenuItem key={type.type_id} value={type.type_id}>
                {type.name}
            </MenuItem>
        ));
    };

    //-------------------------------------------------------------------------------------------------
    const renderStateMenu = () => {
        return tklStore.statuses.map((status) => (
            <MenuItem key={status.state_id} value={status.state_id}>
                {status.state}
            </MenuItem>
        ));
    };

    //-------------------------------------------------------------------------------------------------
    const renderAdditionalInfo = () => {
        const devicesAdditionalInfo = additionalInfo.get(type);

        const onItemNameChanged = (e, index) => {
            const nInfo = [...info];
            nInfo[index].name = e.target.value;
            setInfo(nInfo);
        };

        const onItemValueChanged = (e, index) => {
            const nInfo = [...info];
            nInfo[index].value = e.target.value;
            setInfo(nInfo);
        };

        const onRemoveItem = (index) => {
            const nInfo = [...info];
            nInfo.splice(index, 1);
            setInfo(nInfo);
        };

        const rnd = (item, index) => {
            if (devicesAdditionalInfo.includes(item.name)) {
                return (
                    <TableRow key={index}>
                        <TableCell align="right" sx={{ border: 'none', py: 1, pr: 2, width: '45%' }}>
                            {item.name} :
                        </TableCell>
                        <TableCell sx={{ border: 'none', py: 1 }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                label="Значение"
                                onKeyDown={onKeyDown}
                                value={item.value}
                                onChange={(e) => onItemValueChanged(e, index)}
                            />
                        </TableCell>
                        <TableCell sx={{ border: 'none' }} />
                    </TableRow>
                );
            } else {
                return (
                    <TableRow key={index}>
                        <TableCell sx={{ border: 'none', py: 1 }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                label="Название"
                                onKeyDown={onKeyDown}
                                value={item.name}
                                onChange={(e) => onItemNameChanged(e, index)}
                            />
                        </TableCell>
                        <TableCell sx={{ border: 'none', py: 1 }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                label="Значение"
                                onKeyDown={onKeyDown}
                                value={item.value}
                                onChange={(e) => onItemValueChanged(e, index)}
                            />
                        </TableCell>
                        <TableCell sx={{ border: 'none', py: 1 }}>
                            <IconButton onClick={() => onRemoveItem(index)}>
                                <IndeterminateCheckBoxIcon color="error" />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            }
        };

        return (
            <Table size="small" padding="none">
                <TableBody>{info.map((item, index) => rnd(item, index))}</TableBody>
            </Table>
        );
    };

    //-------------------------------------------------------------------------------------------------
    return (
        <>
            <Dialog open={tkl} onClose={onClose} fullWidth PaperProps={{ style: { overflowY: 'auto' } }}>
                <StyledDialogTitle>{title}</StyledDialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ pt: 2 }}>
                        <FocusableTextField
                            label="sn"
                            size="small"
                            margin="normal"
                            value={sn}
                            onChange={onSnChanged}
                            onKeyDown={onKeyDown}
                        />
                        <FormControl margin="normal" size="small">
                            <InputLabel id="d-type-select">Тип</InputLabel>
                            <Select
                                label="Тип"
                                labelId="d-type-select"
                                variant="outlined"
                                value={type}
                                onKeyDown={onKeyDown}
                                onChange={onTypeChanged}
                            >
                                {renderTypeMenu()}
                            </Select>
                        </FormControl>
                        <TextField
                            label="hw version"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            onKeyDown={onKeyDown}
                            value={hw}
                            onChange={(e) => setHw(e.target.value)}
                        />
                        <TextField
                            label="fw version"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            onKeyDown={onKeyDown}
                            value={fw}
                            onChange={(e) => setFw(e.target.value)}
                        />
                        {renderAdditionalInfo()}
                        <Stack direction="row">
                            <Box sx={{ margin: 'auto' }} />
                            <Tooltip title="Добавить информацию">
                                <IconButton onClick={onAddInfoClick} sx={{ width: 50, height: 50 }}>
                                    <AddBoxIcon color="success" />
                                </IconButton>
                            </Tooltip>
                        </Stack>

                        <TextField
                            label="Примечание"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            multiline
                            maxRows={6}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />

                        <FormControl margin="normal" size="small">
                            <InputLabel id="d-state-select">Состояние</InputLabel>
                            <Select
                                label="Состояние"
                                labelId="d-state-select"
                                variant="outlined"
                                value={state}
                                onChange={onStateChanged}
                            >
                                {renderStateMenu()}
                            </Select>
                        </FormControl>
                        {several ? (
                            <Stack direction="row">
                                <FormControlLabel
                                    label="Несколько"
                                    control={<Checkbox checked={more} onChange={(e) => setMore(e.target.checked)} />}
                                />
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    sx={{ width: '5em' }}
                                    disabled={!more}
                                    value={severalCount}
                                    onChange={onSeveralChanged}
                                />
                            </Stack>
                        ) : null}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="success" onClick={onApplyButtonClick} variant="contained">
                        {applyButtonText}
                    </Button>
                    <Button onClick={onClose} variant="outlined">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
            <SeveralDialog
                open={showSeveralDialog}
                startSn={sn}
                count={severalCount}
                onClose={() => setShowSeveralDialog(false)}
                onApply={onSeveralApplyButtonClick}
            />
        </>
    );
};

//=================================================================================================
const AddDialog = ({ open, onClose }) => {
    const onApplyClick = (tkl) => {
        // try {
        //     await tklStore.create(tkl);
        //     onClose();
        // } catch (e) {
        //     errorHandler(e);
        // }
        onClose();
    };

    return open ? (
        <TklDialog
            title="Добавить устройство"
            applyButtonText="Создать"
            several
            tkl={{ sn: null, type_id: 1, hw_version: '', fw_version: '', state_id: 1, note: '', info: {} }}
            onApply={onApplyClick}
            onClose={onClose}
        />
    ) : null;
};

//=================================================================================================
const EditDialog = ({ item, onClose }) => {
    const errorHandler = useErrorHandler();

    const onApplyClick = async (tkl) => {
        try {
            const fTkl = { ...tkl, id: item.id };
            await tklStore.update(fTkl);
            onClose();
        } catch (e) {
            errorHandler(e);
        }
    };

    return item ? (
        <TklDialog
            title="Редактировать устройство"
            applyButtonText="Сохранить"
            tkl={item}
            onApply={onApplyClick}
            onClose={onClose}
        />
    ) : null;
};

//=================================================================================================
const HistoryDialog = ({ item, onClose }) => {
    const errorHandler = useErrorHandler();

    const [history, setHistory] = useState([]);

    //-------------------------------------------------------------------------------------------------
    useEffect(() => {
        loadHistory();

        return () => {
            if (!item) setHistory([]);
        };
    }, [item]);

    //-------------------------------------------------------------------------------------------------
    const loadHistory = async () => {
        if (item) {
            try {
                const response = await TklService.history(item.id);
                // console.log('setHistory to >>>', response.data);
                setHistory(response.data);
            } catch (e) {
                errorHandler(e);
            }
        }
    };

    //-------------------------------------------------------------------------------------------------
    const renderHistory = () => {
        // console.log('render history >>>', history);

        return history.map((item, index) => {
            return (
                <TableRow key={index}>
                    <TableCell>{localizeTime(item.time)}</TableCell>
                    <TableCell>{item.state}</TableCell>
                    <TableCell>{item.first_name + ' ' + item.last_name}</TableCell>
                    <TableCell>{JSON.stringify(item.info)}</TableCell>
                </TableRow>
            );
        });
    };

    //-------------------------------------------------------------------------------------------------
    if (item && history.length === 0) {
        return (
            <Dialog open onClose={onClose}>
                <StyledDialogTitle>История устройства {item.id}</StyledDialogTitle>
                <DialogContent>Загрузка...</DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog open={Boolean(item)} onClose={onClose} fullWidth>
            <StyledDialogTitle>История устройства {item ? item.id : ''}</StyledDialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Дата</StyledTableCell>
                                <StyledTableCell>Состояние</StyledTableCell>
                                <StyledTableCell>Пользователь</StyledTableCell>
                                <StyledTableCell>Информация</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderHistory()}</TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//=================================================================================================
const NotesHistoryDialog = ({ item, onClose }) => {
    const errorHandler = useErrorHandler();

    const [history, setHistory] = useState([]);

    //-------------------------------------------------------------------------------------------------
    useEffect(() => {
        loadHistory();

        return () => {
            if (!item) setHistory([]);
        };
    }, [item]);

    //-------------------------------------------------------------------------------------------------
    const loadHistory = async () => {
        if (item) {
            try {
                const response = await TklService.notes(item.id);
                // console.log('setHistory to >>>', response.data);
                setHistory(response.data);
            } catch (e) {
                errorHandler(e);
            }
        }
    };

    //-------------------------------------------------------------------------------------------------
    const renderHistory = () => {
        // console.log('render history >>>', history);

        return history.map((item, index) => {
            return (
                <TableRow key={index}>
                    <TableCell>{localizeTime(item.time)}</TableCell>
                    <TableCell>{item.first_name + ' ' + item.last_name}</TableCell>
                    <TableCell>{item.note}</TableCell>
                </TableRow>
            );
        });
    };

    //-------------------------------------------------------------------------------------------------
    if (item && history.length === 0) {
        return (
            <Dialog open onClose={onClose}>
                <StyledDialogTitle>История устройства {item.id}</StyledDialogTitle>
                <DialogContent>Загрузка...</DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog open={Boolean(item)} onClose={onClose} fullWidth>
            <StyledDialogTitle>История примечаний устройства {item ? item.id : ''}</StyledDialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Дата</StyledTableCell>
                                <StyledTableCell>Пользователь</StyledTableCell>
                                <StyledTableCell>Примечание</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderHistory()}</TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//=================================================================================================
const Filters = observer(() => {
    const errorHandler = useErrorHandler();

    const [showAddDialog, setShowAddDialog] = useState(false);

    //filterKey: id | sn | fw | hw
    const createGetFilterValue = (filterKey) => {
        return () => {
            const filter = tklStore.filter;
            const val = filter[filterKey];
            return val ? val : '';
        };
    };

    //-------------------------------------------------------------------------------------------------
    /**
     * Создает обработчик события изменения фильтра (для числовых случаев), который по срабатыванию
     * меняет значение фильтра на переданное (если оно не пустое) и
     * обновляет список устройств.
     * @param {string} filterKey: id | sn - имя свойства фильтра
     * @returns {function(event): Promise<void>}
     */
    const createFilterChangeHandler = (filterKey) => {
        return async (event) => {
            try {
                const inputValue = event.target.value;
                const val = +inputValue;
                if (inputValue === '' || !isNaN(val)) {
                    const filters = {
                        ...tklStore.filter,
                        [filterKey]: inputValue === '' ? undefined : val,
                    };
                    await tklStore.setFilters(filters);
                }
            } catch (e) {
                errorHandler(e);
            }
        };
    };

    //-------------------------------------------------------------------------------------------------
    //filterKey: hw | fw
    const createFilterChangeHandlerString = (filterKey) => {
        return async (event) => {
            try {
                const inputValue = event.target.value;
                const filters = {
                    ...tklStore.filter,
                    [filterKey]: inputValue === '' ? undefined : inputValue,
                };
                await tklStore.setFilters(filters);
            } catch (e) {
                errorHandler(e);
            }
        };
    };

    //-------------------------------------------------------------------------------------------------
    const createMenu = (key, id, name) => {
        const items = tklStore[key];
        const menu = items.map((item) => (
            <MenuItem value={item[id]} key={item[id]}>
                {item[name]}
            </MenuItem>
        ));
        menu.unshift(
            <MenuItem value={null} key={0}>
                Все
            </MenuItem>
        );
        return menu;
    };

    //-------------------------------------------------------------------------------------------------
    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                useFlexGap
                justifyContent="center"
                alignItems="center"
                sx={{ flexWrap: 'wrap' }}
            >
                <Tooltip title="Новое устройство">
                    <Button
                        color="success"
                        onClick={() => {
                            setShowAddDialog(true);
                        }}
                        variant="contained"
                    >
                        Новое
                    </Button>
                </Tooltip>
                <Box sx={{ margin: 'auto' }} />
                <TextField
                    size="small"
                    label="id"
                    sx={{ width: '10em' }}
                    value={createGetFilterValue('id')()}
                    onChange={createFilterChangeHandler('id')}
                />
                <TextField
                    size="small"
                    label="sn"
                    sx={{ width: '10em' }}
                    value={createGetFilterValue('sn')()}
                    onChange={createFilterChangeHandler('sn')}
                />
                <FormControl>
                    <InputLabel id="filter-type-select" size="small">
                        Тип устройства
                    </InputLabel>
                    <Select
                        sx={{ minWidth: '10em' }}
                        label="Тип устройства"
                        labelId="filter-type-select"
                        variant="outlined"
                        size="small"
                        value={createGetFilterValue('dt')()}
                        onChange={createFilterChangeHandler('dt')}
                    >
                        {createMenu('types', 'type_id', 'name')}
                    </Select>
                </FormControl>
                <TextField
                    size="small"
                    label="hw_ver"
                    sx={{ width: '10em' }}
                    value={createGetFilterValue('hw')()}
                    onChange={createFilterChangeHandlerString('hw')}
                />
                <TextField
                    size="small"
                    label="fw_ver"
                    sx={{ width: '10em' }}
                    value={createGetFilterValue('fw')()}
                    onChange={createFilterChangeHandlerString('fw')}
                />
                <FormControl>
                    <InputLabel id="filter-status-select" size="small">
                        Статус
                    </InputLabel>
                    <Select
                        sx={{ minWidth: '10em' }}
                        label="Статус"
                        labelId="filter-status-select"
                        variant="outlined"
                        size="small"
                        value={createGetFilterValue('st')()}
                        onChange={createFilterChangeHandler('st')}
                    >
                        {createMenu('statuses', 'state_id', 'state')}
                    </Select>
                </FormControl>
                <Tooltip title="Количество соответствующих записей">
                    <Typography
                        sx={{
                            minWidth: '3em',
                            textAlign: 'center',
                            padding: '0.5em',
                            border: '1px solid #e0e0e0',
                            borderRadius: '0.2em',
                            color: 'rgba(0, 0, 0, 0.6)',
                        }}
                    >
                        {tklStore.allSize}
                    </Typography>
                </Tooltip>
            </Stack>
            <AddDialog
                open={showAddDialog}
                onClose={() => {
                    setShowAddDialog(false);
                }}
            />
        </>
    );
});

//=================================================================================================
const DeviceAccounting = () => {
    // const { isMobile } = useMedia();
    const errorHandler = useErrorHandler();

    const [editItem, setEditItem] = useState(null);
    const [removeTkl, setRemoveTkl] = useState(null); //отображение диалога удаления + участвует в логике удаления
    const [historyItem, setHistoryItem] = useState(null); //отображение диалога истории
    const [notesItem, setNotesItem] = useState(null); //отображение диалога истории примечаний

    useEffect(() => {
        init();
    }, []);

    //-------------------------------------------------------------------------------------------------
    const init = async () => {
        try {
            await tklStore.loadTypes();
            await tklStore.loadStatuses();
            await tklStore.loadSize();
            await tklStore.loadItems();
        } catch (e) {
            errorHandler(e);
        }
    };

    //-------------------------------------------------------------------------------------------------
    const onPageChange = async (_, page) => {
        try {
            tklStore.setPage(page);
        } catch (e) {
            errorHandler(e);
        }
    };

    const onRowsPerPageChange = async (event) => {
        try {
            tklStore.setPageSize(event.target.value);
        } catch (e) {
            errorHandler(e);
        }
    };

    const onRemove = async () => {
        try {
            tklStore.remove(removeTkl);
            setRemoveTkl(null);
        } catch (e) {
            errorHandler(e);
        }
    };

    //-------------------------------------------------------------------------------------------------
    const renderTableHeader = () => {
        const sortHandler = async (sortColumnName) => {
            try {
                const isAsc = tklStore.sort.column === sortColumnName && tklStore.sort.order === 'asc';
                const sort = {
                    column: sortColumnName,
                    order: isAsc ? 'desc' : 'asc',
                };
                await tklStore.setSort(sort);
            } catch (e) {
                errorHandler(e);
            }
        };

        // prettier-ignore
        const items = [
            { id: 'id',         label: 'id',             sort: true  },
            { id: 'sn',         label: 'sn',             sort: true  },
            { id: 'type_id',    label: 'Тип устройства', sort: true  },
            { id: 'hw_version', label: 'Версия платы',   sort: true  },
            { id: 'fw_version', label: 'Версия ПО',      sort: true  },
            { id: 'state_id',   label: 'Статус',         sort: true  },
            { id: 'note',       label: 'Примечание',     sort: false },
            { id: 'actions',    label: '',               sort: false },
        ];

        const out = [];
        items.forEach((item) => {
            if (item.sort) {
                out.push(
                    <StyledTableCell
                        key={item.id}
                        align="center"
                        sortDirection={tklStore.sort.column === item.id ? tklStore.sort.order : false}
                    >
                        <StyledSortLabel
                            active={tklStore.sort.column === item.id}
                            direction={tklStore.sort.order}
                            onClick={() => sortHandler(item.id)}
                        >
                            {item.label}
                        </StyledSortLabel>
                    </StyledTableCell>
                );
            } else {
                out.push(
                    <StyledTableCell key={item.id} align="center">
                        {item.label}
                    </StyledTableCell>
                );
            }
        });
        return out;
    };

    //-------------------------------------------------------------------------------------------------
    const renderTableBody = () => {
        return tklStore.items.map((item) => (
            <TableRow hover key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.sn}</TableCell>
                <TableCell>{tklStore.type(item.type_id)}</TableCell>
                <TableCell>{item.hw_version}</TableCell>
                <TableCell>{item.fw_version}</TableCell>
                <TableCell>
                    <Tooltip title="История устройства">
                        <Link component={Button} sx={{ textTransform: 'none' }} onClick={() => setHistoryItem(item)}>
                            {tklStore.state(item.state_id)}
                        </Link>
                    </Tooltip>
                </TableCell>
                <TableCell align="center">
                    <Tooltip title="История примечаний">
                        <Link component={Button} sx={{ textTransform: 'none' }} onClick={() => setNotesItem(item)}>
                            <Typography
                                sx={{
                                    maxWidth: '20em',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                            >
                                {item.note}
                            </Typography>
                        </Link>
                    </Tooltip>
                </TableCell>
                <TableCell align="right">
                    <Tooltip title="Редактировать">
                        <IconButton onClick={() => setEditItem(item)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить">
                        <IconButton color="secondary" onClick={() => setRemoveTkl(item)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <>
            <Stack spacing={2}>
                <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Учет устройств
                </Typography>
                <Filters />
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>{renderTableHeader()}</TableRow>
                        </TableHead>
                        <TableBody>{renderTableBody()}</TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50]}
                                        component="div"
                                        count={tklStore.allSize}
                                        rowsPerPage={tklStore.pageSize}
                                        page={tklStore.page}
                                        onPageChange={onPageChange}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Stack>
            <EditDialog item={editItem} onClose={() => setEditItem(null)} />
            <HistoryDialog item={historyItem} onClose={() => setHistoryItem(null)} />
            <NotesHistoryDialog item={notesItem} onClose={() => setNotesItem(null)} />
            <RemoveDialog
                open={Boolean(removeTkl)}
                title="Удалить устройство?"
                text={`Вы действительно хотите удалить устройство ${removeTkl ? removeTkl.id : ''} ?`}
                onApply={onRemove}
                onClose={() => setRemoveTkl(null)}
            />
        </>
    );
};

export default observer(DeviceAccounting);

import { useEffect, useState } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    Button,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import BuyersService from '../../services/buyersService';
import useErrorHandler from '../../services/error-handler';
import { StyledTableCell } from '../StyledComponents';
import CreateBuyersDialog from './CreateBuyersDialog';

//=================================================================================================
class BuyerListStore {
    loading = false;

    size = 0;
    page = 0;
    pageSize = 20;
    items = [];
    filter = {};
    sort = {};

    constructor() {
        makeAutoObservable(this);
    }

    async loadSize() {
        this.loading = true;
        try {
            const response = await BuyersService.size(this.filter);
            this.size = +response.data;
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    async loadItems() {
        this.loading = true;
        try {
            const start = this.page * this.pageSize;
            const response = await BuyersService.list(this.filter, {
                ...this.sort,
                offset: start,
                limit: this.pageSize,
            });
            this.items = response.data;
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    async load() {
        await this.loadSize();
        await this.loadItems();
    }

    async setFilter(filter = {}) {
        this.filter = filter;
        this.page = 0;
        await this.load();
    }

    async setSort(sort = {}) {
        this.sort = sort;
        await this.loadItems();
    }

    async setPage(page) {
        this.page = page;
        await this.loadItems();
    }

    async setPageSize(pageSize) {
        this.pageSize = pageSize;
        await this.loadItems();
    }
}

const buyerStore = new BuyerListStore();

//=================================================================================================
/*
const BuyerItem = ({ buyer }) => {
    const formatAdditional = (additional) => {
        if (!additional || !Array.isArray(additional)) return [];

        const result = [];

        additional.forEach((item) => {
            switch (item.type) {
                case 'telephone':
                    result.push(`Телефон: ${item.value}`);
                    break;
                case 'email':
                    result.push(`Email: ${item.value}`);
                    break;
                case 'address':
                    result.push(`Адрес: ${item.value}`);
                    break;
                case 'contact':
                    const nameField = item.value.find((field) => field.type === 'name');
                    if (nameField) {
                        result.push(`Контакт: ${nameField.value}`);
                    }
                    break;
            }
        });

        return result;
    };

    const renderAdditional = () => {
        const additional = formatAdditional(buyer.additional);
        if (additional.length > 0) {
            const r = additional.map((v, i) => <Typography key={i}>{v}</Typography>);
            return <CardContent>{r}</CardContent>;
        }
    };

    return (
        <Card elevation={3}>
            <CardHeader
                title={
                    <Button href={`/buyers/${buyer.buyer_id}`} sx={{ textTransform: 'none', fontSize: '1.5rem' }}>
                        {buyer.name}
                    </Button>
                }
                subheader={buyer.inn ? 'ИНН: ' + buyer.inn : ''}
            />
            {renderAdditional()}
        </Card>
    );
};
//=================================================================================================
const BuyerList = () => {
    const errorHandler = useErrorHandler();
    // const navigate = useNavigate();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            buyerStore.load();
        } catch (e) {
            errorHandler(e);
        }
    };

    const onPageChange = async (_, page) => {
        try {
            buyerStore.setPage(page);
        } catch (e) {
            errorHandler(e);
        }
    };

    const onRowsPerPageChange = async (event) => {
        try {
            buyerStore.setPageSize(event.target.value);
        } catch (e) {
            errorHandler(e);
        }
    };

    const renderList = () => {
        const items = buyerStore.items;
        return items.map((item) => (
            <Grid item key={item.buyer_id} xs={12} sm={6} md={4}>
                <BuyerItem key={item.buyer_id} buyer={item} />
            </Grid>
        ));
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h5" align="center">
                Заказчики
            </Typography>
            <Grid container spacing={2}>
                {renderList()}
            </Grid>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component="div"
                count={buyerStore.size}
                rowsPerPage={buyerStore.pageSize}
                page={buyerStore.page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </Stack>
    );
};
*/

//=================================================================================================
const BuyerItem = ({ buyer }) => {
    const formatAdditional = (additional) => {
        if (!additional || !Array.isArray(additional)) return [];

        const result = [];

        additional.forEach((item) => {
            switch (item.type) {
                case 'telephone':
                    result.push(`Телефон: ${item.value}`);
                    break;
                case 'email':
                    result.push(`Email: ${item.value}`);
                    break;
                case 'address':
                    result.push(`Адрес: ${item.value}`);
                    break;
                case 'contact':
                    const nameField = item.value.find((field) => field.type === 'name');
                    if (nameField) {
                        result.push(`Контакт: ${nameField.value}`);
                    }
                    break;
            }
        });

        return result;
    };

    const renderAdditional = () => {
        const additional = formatAdditional(buyer.additional);
        if (additional.length > 0) {
            const r = additional.map((v, i) => <Typography key={i}>{v}</Typography>);
            return r;
        }
    };

    return (
        <TableRow hover key={buyer.buyer_id}>
            <TableCell>{buyer.name}</TableCell>
            <TableCell>{buyer.inn ? 'ИНН: ' + buyer.inn : ''}</TableCell>
            <TableCell>{renderAdditional()}</TableCell>
            <TableCell>
                <Tooltip title="Редактировать">
                    <IconButton href={`/buyers/${buyer.buyer_id}`}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

//=================================================================================================
const BuyerList = () => {
    const errorHandler = useErrorHandler();

    const [showAddDialog, setShowAddDialog] = useState(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            buyerStore.load();
        } catch (e) {
            errorHandler(e);
        }
    };

    const onFilterChange = async (event) => {
        try {
            buyerStore.setFilter({ name: event.target.value });
        } catch (e) {
            errorHandler(e);
        }
    };

    const onPageChange = async (_, page) => {
        try {
            buyerStore.setPage(page);
        } catch (e) {
            errorHandler(e);
        }
    };

    const onRowsPerPageChange = async (event) => {
        try {
            buyerStore.setPageSize(event.target.value);
        } catch (e) {
            errorHandler(e);
        }
    };

    const renderItems = () => {
        const items = buyerStore.items;
        return items.map((item) => <BuyerItem key={item.buyer_id} buyer={item} />);
    };

    return (
        <>
            <Stack spacing={2}>
                <Typography variant="h5" align="center">
                    Заказчики
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Button color="success" onClick={() => setShowAddDialog(true)} variant="contained">
                        Новый
                    </Button>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Поиск"
                        margin="normal"
                        onChange={onFilterChange}
                    />
                </Stack>
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Наименование</StyledTableCell>
                                <StyledTableCell>ИНН</StyledTableCell>
                                <StyledTableCell>Дополнительно</StyledTableCell>
                                <StyledTableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderItems()}</TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 50]}
                                        component="div"
                                        count={buyerStore.size}
                                        rowsPerPage={buyerStore.pageSize}
                                        page={buyerStore.page}
                                        onPageChange={onPageChange}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Stack>
            <CreateBuyersDialog
                open={showAddDialog}
                onClose={() => setShowAddDialog(false)}
                onApply={() => {
                    setShowAddDialog(false);
                    init();
                }}
            />
        </>
    );
};
export default observer(BuyerList);
